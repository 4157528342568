.dashboard {
    width: 100vw;
    top: 84px;
    min-height: calc(100vh - 84px);
    height: auto;
  }
  
  .dashboardBody {
    width: calc(100vw - 240px);
    height: calc(100vh - 74px);
    margin-left: 240px;
    padding: 10px 40px;
    margin-top: 74px;

    @media (max-width : 900px){
        margin-left: 0px;
    }
  }
  
  .tabs {
    display: flex;
    font-weight: 600;
    font-size: 13px;
    gap: 10px;
    padding: 0px 15px;
  }
  
  .title {
    color: #07070766 !important;
    font-weight: 800;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  
  .tabContainer {
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .tabContainer:hover {
    background-color: #F4F7FB;
  }
  
  .selectedTabs {
    background: #8DC63F !important;
  }
  
  .pricingPlansContainer {
    z-index: 1;
    position: relative;
  }
  
  @media (max-width: 900px) {
    .dashboardBody {
      width: 100%;
    }
  }
  