.detailsBarChartWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  gap: 15px;
  margin: 6px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #a4acb933;
}

.detailsBarChartWrapperHead {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  gap: 15px;
  margin: 6px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #a4acb933;
}

.abc {
  padding-top: 24px;
}

.detailAnalytic {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  gap: 15px;
  margin: 6px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #a4acb933;
}

.location {
  position: relative;
}

.samplingGoal {
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9c9c9c;
  position: absolute;
  height: 100%;
  top: -5px;
  left: 13px;
}

.sliderClasses {
  padding-left: 15px !important;
  padding-top: 4px;
}

@media (max-width: 1280px) {
  .samplingGoal {
    top: 0px;
    left: 5px;
  }
}

.samplingGoal {
  position: relative;
}

.samplingGoalIncDec {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.samplingValue {
  margin-right: 15px;
}

@media (max-width: 1280px) {
  .samplingValue {
    margin-right: 12px;
  }
}

.locationText {
  position: absolute;
  top: -8px;
  left: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.4375em;
  padding: 0;
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 20;
  font-family: Public-sans;
  font-size: 12px;
  text-align: left;
}

.updateSurveyBg {
  display: flex;
  flex-direction: column;
  background-color: #f1f2f3;
  border-radius: 4px;
  height: fit-content;
  margin: 4px;
}

.overallPorgress {
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: #767676;
  font-size: 16px;
  line-height: 18.8px;
}

.infoIconSection {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.infoIconLeftBorder {
  border-left: 1px #e7e7e9 solid;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoText {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 36px;
  line-height: 16.45px;
  letter-spacing: -0.02em;
  padding: 6px 12px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #611511;
  margin-left: 10px;
  background-color: #ffebe9;
}
.infoText2 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 36px;
  line-height: 16.45px;
  letter-spacing: -0.02em;
  padding: 6px 12px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #167421;
  margin-left: 10px;
  background-color: #dbf6cb;
}

.analytics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.analyticsText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailedAnalytics {
  border: 1px solid;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.1px;
  padding: 8px;
  border: 1px solid;
  border-color: #e7e7e9;
  box-shadow: 0px 1px 2px 0px #a4acb933;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: 4px;
  display: flex;
  gap: 8px;
}

.overallmetric {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.metricSection {
  display: flex;
  flex-direction: column;
}

.metricTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-top: 7px;
}

.total {
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 23.5px;
}

.overallChange {
  width: 48px;
  height: 18px;
  border-radius: 40px;
  padding: 2px 5px;
  gap: 4px;
  background-color: #fee4e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowClasses {
  display: flex;
  justify-content: center;
  align-items: center;
}

.numericChange {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.1px;
  letter-spacing: -0.02em;
  color: #c80708;
}

.metricTitle {
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.1px;
}
.progressGraph {
  display: flex;
  flex-direction: column;
}

.updateInfo {
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
}

.horizontalLine {
  border-top: 0.64px solid;
  border-color: #e1e1e1;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .detailsBarChartWrapper {
    align-items: center;
  }
}

.detailsBarChartContainer {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .detailsBarChartContainer {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}

.recipientButton {
  width: 130px;
  height: 37px;
}

/* Configurtation TAB  */

.container {
  width: 100%;
  display: flex;
  gap: 50px;
  /* background:#FFFFFF; */
}

.publishCheckbox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background:#FFFFFF; */
}

.sidebar {
  max-width: 250px;
  width: 250px;
}

.iconsClasses {
  display: flex;
  gap: 10px;

  align-items: center;
}

.tabList {
  border: 1px solid #e7e7e9;
  background: #ffffff;
  border-radius: 6px;
  height: "auto";
}

.tab {
  padding: 8px 8px;
  margin: 8px 8px;
  cursor: pointer;
  font-weight: 500;
  color: #111111;
  /* border-bottom: 1px solid #eeeeee; */
  font-size: 15px;
  border-radius: 6px;
}

.activeTab {
  background: #e0e0e0;
  /* font-weight: bold; */
}

.content {
  width: 80%;
}

/* OverView Publish/Unpublish */
.container {
  display: flex;
  border: 1px solid #e7e7e9;
  border-radius: 4px;
  /* background: #00000030;
   */
  padding: 5px;
  gap: 10px;
}

.publishEnabled {
  background-color: #8dc63f;
  color: white;
  font-weight: 500;
  cursor: pointer;
  line-height: 16.45px;
  padding: 9px 25px;
  font-size: 14px;
  border-radius: 4px;
}

.publishDisabled {
  background-color: #f6f6f6;
  color: #9c9c9c;
  font-weight: 500;
  cursor: pointer;
  line-height: 16.45px;
  padding: 9px 25px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #11111114;
}

.unpublishEnabled {
  background-color: #f6f6f6;
  color: #9c9c9c;
  font-weight: 500;
  cursor: pointer;
  line-height: 16.45px;
  padding: 9px 25px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #11111114;
}

.deleteIconAndValidations {
  display: flex;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mobileErrorsTrashIcon {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobileValidations {
  display: flex;
  flex-direction: column;
}

.unpublishDisabled {
  background-color: #ff3f3c;
  color: white;
  font-weight: 500;
  cursor: pointer;
  line-height: 16.45px;
  padding: 9px 25px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #11111114;
}

@media (max-width: 960px) {
  .publishEnabled,
  .publishDisabled,
  .unpublishEnabled,
  .unpublishDisabled {
    padding: 10px 12px;
    font-size: 12px;
  }
}
