.shimmerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Public-sans;
    font-weight: 600;
    gap: 40px;
  }
  
  .shimmerWrapperPreview {
    padding: 150px 20px;
  }
  
  .shimmerWrapperDefault {
    padding: 10px 0px;
  }
  
  .shimmerWrapper section {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width:900px){
        flex-direction: column;
        align-items: center;
    }
  }
  
  .shimmerCard {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 40px 24px;
    gap: 38px;
    width: 380px;
    height: 550px;
    background: white;
    box-shadow: 0px 4px 30px 0px #0000001A;
  }
  
  .shimmerCard div {
    border-radius: 4px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  .shimmerText {
    height: 20px;
    margin-bottom: 8px;
  }
  
  .shimmerPrice {
    height: 40px;
    width: 50%;
    margin-bottom: 10px;
  }
  
  .shimmerButton {
    height: 44px;
    width: 100%;
    margin: 4px 0;
  }
  
  .shimmerService {
    height: 20px;
    margin-bottom: 6px;
  }

  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  