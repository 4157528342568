.root {
    z-index: 999;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid #d7d7d7;
    height: 74px;
    max-width: none;
    padding-left: 50px;
    padding-right: 70px;
    display: flex;
    flex-direction: row;
  }
  
  .textSwitch {
    color: #07070780;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 2px;
    font-size: 12px;
  }
  
  .selectedOption,
  .selectedOptionEmployee {
    color: #070707;
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    text-transform: capitalize;
  }
  
  .AppBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    /* z-index: 99999999; */
  }
  
  .userMenuContainer,
  .loginGetStarted {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .loginGetStarted {
    gap: 10px;
  }
  
  .container {
    padding: 0;
  }
  
  .logo {
    margin-right: 14px;
  }
  
  .organisationSelector {
    border: 1px solid #d6d6d6;
    padding: 4px 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #e9e9e9;
    cursor: pointer;
  }
  
  @media (max-width: 767px) {
    .organisationSelector,
    .hideEmployeeDetail {
      display: none;
    }
  }
  
  .organisationSelectorLabel {
    color:#07070780;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
  }
  
  .organisationSelectorValue {
    color: #070707;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 1.3;
  }
  
  .cursorPointer {
    cursor: pointer;
  }
  
  .userProfileRoleTitle {
    color: #000;
    font-size: 12px;
    margin-top: 2px;
  }
  
  .menuItemColor {
    color: #000;
  }
  
  .addIcon {
    border: 1px solid;
    border-color:#0F0F0F33;
    border-radius: 4px;
  }
  
  .textPrimary {
    color: #173a5e;
  }
  
  .textSecondary {
    color: #070707;
  }
  
  .selectedItemColor {
    color: var(--theme-palette-primary-main);
  }
  
  .navItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  
  @media (max-width: 1200px) {
    .navItems {
      gap: 10px;
    }
  }
  
  .isActiveLink,
  .isActive {
    border: none;
    outline: 0;
    color: #0066f9;
  }
  .isActiveButton {
    color: #0066f9;
    white-space: nowrap;
  }
  
  .isNotActive {
    border: none;
    white-space: nowrap;
    outline: 0;
  }
  
  .noOutline:focus,
  .noOutlineButton:focus,
  .noOutlineButtonProvisining:focus {
    outline: none;
  }
  