.next-steps .fa-link {
  margin-right: 5px;
}
/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}
.next-steps .col-md-5 {
  margin-bottom: 3rem;
}
@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}
.spinner {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.result-block-container .result-block {
  opacity: 1;
}
#navItem a {
  color: #26262A;
  font-family: "Public-sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.navbar .router-link-exact-active {
  color: #0066F9 !important;
  border-bottom: none;
}

/* @font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/Graphik-Font-Family/GraphikRegular.otf');
  font-weight: normal;
  font-style: normal;
} */