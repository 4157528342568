.sidebar {
    width: 240px;
    top: 74px;
    height: calc(100vh - 74px);
    background: #FFFFFF;
    border-right: 1px solid #d7d7d7;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
  }
  
  .selectedOption {
    color: #070707;
    font-weight: 600;
    line-height: 18px;
    text-transform: capitalize;
  }
  
  .organisationSelector {
    border: 1px solid #D6D6D6;
    padding: 4px 10px 4px 4px;
    border-radius: 8px;
    display: flex;
    height: 51px;
    width: auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    background: #E9E9E9;
    cursor: pointer;
  }
  
  .textSwitch {
    color: #07070780;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 2px;
    font-size: 12px;
  }
  
  .dividerClasess {
    margin-top: 20px;
  }
  
  .sidebarFooter {
    background: #2D3338;
    height: 84px;
    width: 240px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .sidebarFooterTitle {
    color: white;
    margin: 5px;
    font-size: 12px;
  }
  
  .listStyle {
    list-style: none;
  }
  
  @media (min-width: 900px) {
    .organisationSelector {
      display: none;
    }
    .dividerClasess {
      display: none;
    }
  }
  
  @media (max-width: 900px) {
    .sidebar {
      width: inherit;
      z-index: 50;
    }
    .sidebarFooter {
      display: none;
    }
  }
  