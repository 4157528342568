.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width) {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
}

.headerInfo {
  /* max-width: 70%; */
  width: 100%;

  @media (max-width:900px){
    max-width: 100%;
  }
}

.title {
  color: #070707;
  font-weight: 700;
  font-size: 24px;
  font-family: 'Public-sans';

  @media (max-width:600px){
    font-size: 20px;
  }
}

.description {
  font-weight: 400;
  font-size: 15px;
  font-family: 'Public-sans';

  @media (max-width:600px){
    font-size: 14px;
  }
}

.description p {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
}


.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}