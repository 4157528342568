.root {
    width: 100vw;
    top: 84px;
    min-height: calc(100vh - 84px);
    height: auto;
  }
  
  .body {
    width: full;
    height: calc(100vh - 74px);
    margin-left: 240px;
    padding: 10px 40px;
    margin-top: 74px;
  }
  
  .onSideBarOpen {
    width: full;
    height: calc(100vh - 74px);
    padding: 10px 40px;
    margin-top: 74px;
  }
  
  .tabs {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    gap: 14px;
    padding: 0px 15px;
  }
  
  .tabs img {
    height: 24px;
    width: auto;
  }
  
  .title {
    color: #07070766 !important;
    font-weight: 800;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  
  .tabContainer {
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .tabContainer:hover {
    background-color: #f4f7fb;
  }
  
  .selectedTabs {
    background: #8dc63f !important;
  }
  
  @media (max-width: 600px) {
    .body {
      width: 100vw;
      margin-left: 0px;
    }
  }

  .modalPrimaryButton{
    padding: 10px 40px;
    border-radius: 50px;
  }

  .modalSecondaryButton{
    padding: 10px 40px;
    border-radius: 50px;
  }
  