.footerWrapper {
  display: flex;
  flex-direction: row;
  background-color: rgb(25, 30, 42);
  min-height: 400px;
  padding: 50px 100px;
  justify-content: space-between;
  gap: 100px;
  width: 100%;
}

.footerLinksWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.socialContainer {
  max-width: 280px;
}

.socialLinksWrapper {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.socialIconsWrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.socialText {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Public-sans', sans-serif;
  text-align: left;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'Public-sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.linksSection {
  font-size: 14px;
  line-height: 22px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .footerWrapper {
    gap: 40px;
  }

  .socialContainer {
    max-width: 180px;
  }

  .linkContainer {
    gap: 40px;
  }
}

@media (max-width: 900px) {
  .footerWrapper {
    padding: 50px 50px;
  }

  .socialContainer {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .footerWrapper {
    flex-direction: column;
    min-height: 1200px;
    justify-content: space-evenly;
  }

  .footerLinksWrapper {
    flex-direction: column;
    justify-content: space-evenly;
    gap: 100px;
  }

  .linkContainer {
    align-items: start;
  }
}
