.surveyFooter {
  position: fixed;
  width: 100%;
  bottom: 130px;
  left: 0;
}

.customizedDialog {
  width: 100%;
}
.customizedDialog .MUIDialog-scrollPaper {
  width: 700px;
  margin: auto;
  max-width: 100%;
}
.customizedDialog .MUIDialog-scrollPaper .MUIDialog-paper {
  width: 100%;
}
