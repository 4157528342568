body {
  margin: 0;
  padding: 0;
  font-family: Public-sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
code {
  font-family: Public-sans, "Courier New", "Noto Sans" monospace;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Public-sans";
  src: url("../public/fonts/Graphik-Font-Family/GraphikRegular.otf");
  font-weight: 400;
  font-style: regular;
}
@font-face {
  font-family: "Graphik web";
  src: url("../public/fonts/Graphik-Font-Family/GraphikMedium.otf");
  font-weight: 500;
  font-style: medium;
}
@font-face {
  font-family: "Graphik web";
  src: url("../public/fonts/Graphik-Font-Family/GraphikSemibold.otf");
  font-weight: 600;
  font-style: semi-bold;
}
@font-face {
  font-family: "Graphik web";
  src: url("../public/fonts/Graphik-Font-Family/GraphikBold.otf");
  font-weight: 700;
  font-style: bold;
}
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: white;
  color: black;
}
@font-face {
  font-family: "Public-sans";
  src: url("../public/fonts/Public-sans/PublicSans-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Public-sans";
  src: url("../public/fonts/Public-sans/PublicSans-Bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Public-sans";
  src: url("../public/fonts/Public-sans/PublicSans-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Public-sans";
  src: url("../public/fonts/Public-sans/PublicSans-Thin.woff2");
  font-weight: 300;
  font-style: normal;
}
.custom-range-inputs-grid .ant-picker-dropdown {
  height: auto !important;
  top: 65px !important;
}

.custom-range-inputs-grid .ant-picker-panel {
  &:last-child {
    width: 0;
    .ant-picker-header {
      position: absolute;
      right: 0;
      .ant-picker-header-prev-btn,
      .ant-picker-header-view {
        visibility: hidden;
      }
    }

    .ant-picker-body {
      display: none;
    }

    @media (min-width: 768px) {
      width: 280px !important;
      .ant-picker-header {
        position: relative;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: initial;
        }
      }

      .ant-picker-body {
        display: block;
      }
    }
  }
}