/* Hero Section  */

.heroWrapper {
  width: 100%;
  margin: auto;
  position: relative;
}

.heroWrapperTopIcon {
  background-image: url("../../assets/HomeHeroTop.svg");
  background-size: cover;
  height: 248.92px;
  width: 100px;
  position: absolute;
  right: 0px;
  transform: rotate(-0.9deg);
}

.heroContainer {
  padding: 150px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  width: 60%;

  @media (max-width: 960px) {
    width: 100%;
    padding: 150px 40px 40px 40px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 150px 20px 40px 20px;
  }
}

.heroTitle {
  font-weight: 700;
  line-height: 75px;
  font-size: 70px;
  text-align: center;
  color: #29292b;

  @media (max-width: 960px) {
    font-size: 40px;
    line-height: 40px;
  }

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 30px;
  }
}

.heroDescription {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 32px;
  padding: 0px 120px;
  color: #0f172a;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.highlightedText {
  color: #8dc63f; 
}

.highlightedLink {
  text-decoration: underline;
  color: #26262a;
}

 /* Hero Button Section  */

.heroButtonContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.heroButtonContainer .heroButtonPrimary {
  height: 50px;
  text-transform: capitalize;
  border-radius: 40px;
  padding: 8px 60px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 8px 25px;
  }
}

.heroButtonContainer .heroButtonPrimary:hover {
  background: #7aaf35 !important;
  color: #fff !important; 
}

.heroButtonContainer .heroButtonSecondary {
  height: 50px;
  text-transform: capitalize;
  border-radius: 40px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 8px 10px;
  }
}

.heroButtonContainer .heroButtonSecondary:hover {
  background: #f7f7f7 !important; 
}


/* Video Section  */

.videoWrapper {
  position: relative;
  margin: 350px 0px 0px 0px;
}

.videoInfoWrapper {
  background: #0b109f;
  height: 800px;

  @media (max-width: 600px) {
    height: 700px;
  }

  @media (min-width: 600px) {
    height: 800px;
  }
}

.videoInfoContainer {
  padding: 500px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  gap: 15px;
  position: relative;

  @media (max-width: 600px) {
    height: 20px;
    padding: 250px 40px 40px 40px;
  }

  @media (min-width: 600px) {
    height: 300px;
  }
}

.videoInfoTitle {
  font-size: 42px;
  text-align: center;

  @media (max-width: 960px) {
    font-size: 30px;
  }

  @media (max-width: 600px) {
    font-size: 22px;
  }
}

.videoInfoDescription {
  font-size: 20px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 17px;
  }
}

.videoInfoButton {
  padding: 12px 180px;
  border-radius: 40px;

  @media (max-width: 600px) {
    padding: 12px 60px;
  }

  @media (max-width: 920px) {
    padding: 12px 120px;
  }
}

.videoContainer {
  box-shadow: 0px 0px 20px 0px #0000000f;
  height: 700px;
  margin: 0px auto;
  position: absolute;
  bottom: 400px;
  left: 70px;
  right: 70px;
  background: white;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 220px;
    bottom: 600px;
    left: 30px;
    right: 30px;
  }

  @media (min-width: 600px) {
    height: 500px;
  }

  @media (min-width: 920px) {
    height: 600px;
  }
}

 /* Info Section  */


 .infoSection {
  background: #0d121f;
  color: #ffffff;
}

.infoSectionWrapper {
  padding: 100px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.infoSectionTitle {
  color: #0066f9;
  text-align: center;
}

.infoTitleSecondary {
  text-align: center;
  font-size: 40px;
  width: 60%;
}

.infoDescription {
  text-align: center;
  width: 50%;
  font-size: 20px;
}

.infoCardWrapper {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 960px) {
  .infoTitleSecondary {
    font-size: 25px;
    width: 100%;
  }

  .infoDescription {
    font-size: 17px;
    width: 100%;
  }

  .infoCardWrapper {
    flex-direction: column;
    align-items: center;
  }
}

.infoCardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  height: 350px;
  width: 350px;
  color: #ffffff;
  margin: 50px 0px 80px 0px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .infoCardContainer {
    margin: 10px 0px;
  }
}

.infoIconsContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.infoButtonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0px 0px 150px 0px;
}

.infoButtonPrimary {
  padding: 10px 40px;
  border-radius: 50px;
}

.infoButtonSecondary {
  padding: 10px 40px;
  border-radius: 40px;
  font-size: 14px;
  color: #fff;
  border: 2px solid #ffffff;
}


/* //feature list  */

.featureList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  padding: 60px 120px; 
}

.featuresEvenCard {
  flex-direction: row-reverse;
}

.featuresOddCard {
  flex-direction: row;
}

.featuresLeft {
  width: 100%;
  display: flex;
  background: #f1f5f9;
  border-radius: 20px;
  justify-content: center;
}

.featuresLeft img {
  max-width: 100%;
  padding: 100px 30px; 
}

.featuresRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.featuresRightTitle {
  font-weight: 700;
  line-height: 50.4px;
  font-size: 42px;
}

.featuresRightDescription {
  font-weight: 400;
  font-size: 18px;
  color: #0f172a;
  line-height: 30px;
}

@media (max-width: 960px) {
  .featureList {
    padding: 0px 20px; 
    flex-direction: column; 
    gap: 20px; 
  }

  .featuresLeft,
  .featuresRight {
    width: 100%;
  }

  .featuresRightTitle {
    font-size: 32px;
  }

  .featuresRightDescription {
    font-size: 16px; 
  }
}


/* Banner components  */
.bannerWrapper {
  height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fef5d4;
  margin: 100px 0px 0px 0px;
  padding: 0px 30px;

  @media (min-width: 920px) {
    justify-content: space-evenly;
    gap: 20px;
  }

  @media (max-width: 920px) {
    flex-direction: column;
    padding: 75px 30px;
  }
}

.bannerContainer {
  max-width: 500px;
}

.bannerTitle {
  font-size: 40px;

  @media (max-width: 920px) {
    font-size: 25px;
    text-align: center;
  }
}

.bannerDescription {
  font-size: 17px;

  @media (max-width: 920px) {
    font-size: 15px;
    text-align: center;
  }
}

.bannerWrapper .bannerContainer .bannerButton {
  border-radius: 40px;
  padding: 10px 40px;
  font-size: 15px;

  @media (max-width: 920px) {
    font-size: 12px;
  }
}
