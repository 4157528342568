@keyframes blink {
    0% {
      background-color: #d7dce7;
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: #d7dce7;
    }
  }
  
  .blink {
    animation: blink 1s ease-in-out;
    animation-iteration-count: 1; 
  }
  